<template>
    <div>
        <b-card>
            <b-container
                class="mb-1"
                fluid
            >
                <b-row
                    class="mb-1"
                >
                    <b-col sm="2">
                        Name of user group:
                    </b-col>
                    <b-col sm="6">
                        <b-form-input
                            v-model="groupData.name"
                            placeholder="Name of user group (ex. Super admin) or (ex. Fulfillment room)"
                        />
                    </b-col>
                </b-row>
                <b-row
                    class="mb-1 text-right"
                >
                    <b-col
                        sm="8"
                    >
                        <b-button
                            variant="primary"
                            :disabled="saving"
                            @click="addUserGroup"
                        >
                            <template v-if="saving">
                                <b-spinner small type="grow" />
                                Saving...
                            </template>
                            <template v-else>
                                <span>
                                    Create user group
                                </span>
                            </template>
                        </b-button>
                    </b-col>
                </b-row>
            </b-container>
        </b-card>
    </div>
</template>

<script>
import Axios from 'axios'
import {
    BCard, BContainer, BButton, BSpinner, BCol, BRow, BFormInput,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
    name: 'settings.user-groups.create',
    components: {
        BCard,
        BContainer,
        BButton,
        BSpinner,
        BCol,
        BRow,
        BFormInput,
        // eslint-disable-next-line vue/no-unused-components
        ToastificationContent,
    },
    data() {
        return {
            errors: [],
            groupCreated: false,
            groupData: {
                name: '',
            },
            saving: false,
        }
    },
    methods: {
        addUserGroup() {
            this.saving = true
            this.groupCreated = false
            this.errors = []
            Axios({ url: `${process.env.VUE_APP_API_HTTP_ROOT}user-groups/create`, data: this.groupData, method: 'POST' })
                .then(() => {
                    this.saving = false
                    this.groupCreated = true
                    this.groupData.name = ''

                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Success!',
                            icon: 'CheckIcon',
                            text: 'User group was created successfully.',
                            variant: 'primary',
                        },
                    })
                })
                .catch(err => {
                    for (const errorGroupKey of Object.keys(err.response.data.errors)) {
                        const errorGroup = err.response.data.errors[errorGroupKey]
                        this.errors = this.errors.concat(errorGroup)
                    }
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Error!',
                            icon: 'XIcon',
                            text: 'There was an error creating the user group and it cannot be finalized at this moment.',
                            variant: 'primary',
                        },
                    })
                    this.saving = false
                })
        },
    },
}
</script>
